import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateParamArray } from '@app/core/helpers/params-generator';
import { VatQuotesSettingsUpdate } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class VatQuoteService {
  constructor(private http: HttpClient) {}

  load(propertyId: number[]) {
    return this.http.get(
      `properties/vat_quotes/settings?${generateParamArray(
        'property_id',
        propertyId,
      )}`,
    );
  }

  update(data: VatQuotesSettingsUpdate) {
    return this.http.put(`properties/vat_quotes/settings`, {
      ...data,
      property_id: data.property_id,
    });
  }

  resetBillsVatQuotes(property_id: number[]) {
    return this.http.post(`properties/vat_quotes/recalculate_all_bills`, {
      property_id,
    });
  }
}
